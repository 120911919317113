import React from "react";
import { Outlet } from "react-router-dom";
// import Footer from "../components/Footer/Footer";
import { Box } from "@mui/material";
import { Wrapper } from "./styles";
import { Footer, Navbar } from "../components";
// import "./styles.css"; // Import the CSS file

const SharedLayout = () => {
  return (
    <Wrapper>
      <Box className="layout">
        <div className="background"></div>
        <Navbar />
        <Box className="body">
          <Outlet />
        </Box>
        <Footer />
      </Box>
    </Wrapper>
  );
};

export default SharedLayout;
