import { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  tableCellClasses,
  Tooltip,
  IconButton,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import toast from "react-hot-toast";
import { customFetchJson } from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import { AdminModal, SearchBar } from "../../../components";
import DownloadIcon from "@mui/icons-material/Download";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Dashboard = () => {
  const [modalTeam, setModalTeam] = useState({});
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const adminCred = JSON.parse(sessionStorage.getItem("adminCred"));
  const [roundNo, setRoundNo] = useState(0);
  const [rows, setRows] = useState([]);
  const [currRoundTeam, setCurrRoundTeam] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchTeams = async () => {
    try {
      // console.log("fetching ....");
      const resp = await customFetchJson.post("/api/allTeams", adminCred);
      console.log(resp.data);
      setRows(resp.data);
      setCurrRoundTeam(resp.data[0]);
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong while fetching teams !");
    }
  };

  useEffect(() => {
    if (!adminCred?.eventName || !adminCred?.id || !adminCred?.password) {
      toast.error("Login Error !");
      navigate("/admin");
      return;
    }
    fetchTeams();
  }, []);

  const handleCheckboxChange = (index, field) => {
    const updatedRows = [...currRoundTeam];
    const updatedStage = [...updatedRows[index].stages];
    updatedStage[roundNo][field] = !updatedStage[roundNo][field];
    updatedRows[index] = {
      ...updatedRows[index],
      stages: updatedStage,
    };
    setRows(updatedRows);
  };

  const handleSubmit = async () => {
    try {
      const result = currRoundTeam.map((row) => ({
        teamId: row._id,
        attendance: row.stages[roundNo].attendance ? 1 : 0,
        qualified: row.stages[roundNo].qualified ? 1 : 0,
        stage: roundNo,
      }));

      await customFetchJson.post("/api/mark", {
        ...adminCred,
        incomingState: result,
      });
      fetchTeams().then(() => {
        toast.success("Updated successfully !");
      });
    } catch (e) {
      console.log("Error while submitting = ", e);
    }
  };

  const handleRountNo = (val) => {
    setRoundNo(val);
    setCurrRoundTeam(rows[val]);
  };

  const handleDelete = async (id, teamName) => {
    await customFetchJson.post(`/api/deleteTeam/${id}`, adminCred);
    fetchTeams().then(() => {
      toast.success(`${teamName} deleted successfully !`);
    });
  };

  const handleModal = (idx) => {
    setModalTeam(currRoundTeam[idx]);
    setOpen(true);
  };

  const handleSearch = () => {
    const filteredEvents = (rows[roundNo] || []).filter((team) => {
      // console.log(team);
      return team.teamName.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setCurrRoundTeam(filteredEvents);
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  const handleLogout = () => {
    sessionStorage.clear("adminCred");
    navigate("/admin");
    toast.success("Logout successfully");
  };

  const CSVDownload = async () => {
    try {
      const resp = await customFetchJson.post(`/api/generate-sheet`, {
        name: adminCred.eventName,
      });
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Concetto-event-data.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        my: "2em",
      }}
    >
      <AdminModal open={open} setOpen={setOpen} {...modalTeam} />
      <Typography variant="h3" mb="0.5em">
        Admin Dashboard
      </Typography>
      <Box>
        <Tooltip title="Download as CSV" sx={{ mr: 4 }}>
          <IconButton onClick={() => CSVDownload()}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
        <Button variant="contained" onClick={handleLogout}>
          Logout
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          mb: "1em",
        }}
      >
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Round</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={roundNo}
              label="Select Round"
              onChange={(e) => handleRountNo(e.target.value)}
            >
              {(rows || []).map((_, idx) => (
                <MenuItem key={idx} value={idx}>
                  {idx}
                </MenuItem>
              ))}
              {/* <MenuItem value={1}>1</MenuItem> */}
              {/* <MenuItem value={2}>2</MenuItem> */}
              {/* <MenuItem value={3}>3</MenuItem> */}
            </Select>
          </FormControl>
        </Box>
        <Typography variant="h6">
          Total Teams participated in this round: {rows[roundNo]?.length}
        </Typography>
        <SearchBar
          text={searchTerm}
          setText={setSearchTerm}
          styles={{
            backgroundColor: "#dedede",
            "&:hover": { backgroundColor: "#dedede" },
          }}
        />
      </Box>
      <Box component="form">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Team Name</StyledTableCell>
                <StyledTableCell>Attendance</StyledTableCell>
                <StyledTableCell>Is Qualified For Next Round ?</StyledTableCell>
                <StyledTableCell>Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currRoundTeam.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ cursor: "pointer", width: 200 }}
                    onClick={() => handleModal(idx)}
                  >
                    {row.teamName}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    <Checkbox
                      checked={row.stages[roundNo].attendance}
                      onChange={() => handleCheckboxChange(idx, "attendance")}
                    />
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    <Checkbox
                      checked={row.stages[roundNo].qualified}
                      onChange={() => handleCheckboxChange(idx, "qualified")}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDelete(row._id, row.teamName)}
                  >
                    <DeleteIcon />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {roundNo !== (rows || []).length - 1 && (
        <Button variant="contained" sx={{ mt: "1em" }} onClick={handleSubmit}>
          Submit
        </Button>
      )}
    </Container>
  );
};

export default Dashboard;
