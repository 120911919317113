import styled from "@emotion/styled";
import Box from "@mui/material/Box";

export const Wrapper = styled(Box)({
    "h1":{
        fontFamily: `"ReFormationSansRegular", sans-serif !important`,
    },
    '@import url("https://fonts.googleapis.com/css2?family=Genos")': true,
    "#main": {
      width: "100%",
      minHeight: "94vh",
      padding: "40px",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "65px 75px",
      // backgroundColor: "rgb(0, 0, 0)",
      fontSize: "'Genos'"
    },
    "#main .card": {
      objectFit: "contain",
      borderRadius: ["18px", "25px"],
      width: "325px",
      background: "rgba(255, 255, 255, 0.1)",
      backdropFilter: ["blur(5px)", "blur(5px) saturate(300%)"],
      boxShadow: [
        "20px 20px 50px rgba(0, 0, 0, 0.5)",
        "inset -6px -4px 2px rgba(255, 255, 255, 0.03)"
      ],
      overflow: "hidden",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      transition: "transform 0.3s 0.1s",
      borderTop: "2px solid rgba(255, 255, 255, 0.5)",
      borderLeft: "2px solid rgba(255, 255, 255, 0.5)",
      fontFamily: "'Genos'",
      padding: "20px 0 0 0",
      WebkitBackdropFilter: "blur(5px) saturate(300%)",
      backgroundColor: "rgba(17, 25, 40, 0.53)",
      border: "1px solid rgba(255, 255, 255, 0.125)"
    },
    ".imgContainer": { borderRadius: "8px" },
    "#main .card:hover": { transform: "scale(1.1)", zIndex: 2 },
    "#main .card .title": {
      fontSize: "2rem",
      marginBottom: "10px",
      color: "#fafafa"
    },
    "#main .card .text": { fontSize: "1rem", color: "#fafafa" },
    ".img": { borderRadius: "18px" },
    "@media only screen and (max-width: 980px)": {
      "#main .card": { width: "320px", height: "350px" },
      "#main .card .title": { fontSize: "1.25rem" }
    },
    ".container": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "Genos",
      padding: "4rem 0 0 0",
      // backgroundColor: "#000"
    },
    ".ttl": { fontSize: "3rem", color: "#fafafa" },
    "@media only screen and (max-width: 650px)": {
      ".container": { height: "25vh" },
      ".ttl": { fontSize: "3rem", padding: "0 0 30px 0" },
      "#main .card": { width: "100%", height: "100%", padding: "0 0 0 0" }
    },
    ".parDiv":{
      marginTop:"5%"
    }
});