import React from "react";
import { Box, Typography } from "@mui/material";
import { Wrapper } from "./styles.jsx";
import { SponsorsList } from "./SponsorList.js";

const Sponsors = () => {
  return (
    <Wrapper>
      <section className="sponsors" id="sponsors">
        <Box>
          <Typography variant="h2" className="section-header">
            Past Sponsors
          </Typography>
          <div className="sponsors-list">
            {SponsorsList.map((sponsor, idx) => {
              return (
                <a
                  key={idx}
                  href={sponsor.link}
                  className={sponsor.recolor}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={sponsor.img} alt={sponsor.cat} loading="lazy" />
                </a>
              );
            })}
          </div>
        </Box>
      </section>
    </Wrapper>
  );
};

export default Sponsors;
