import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AdminModal = ({
  open,
  setOpen,
  teamName,
  member = [],
  ...otherProps
}) => {
  //   console.log(otherProps, member);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="team-details"
        aria-describedby="Event team details"
      >
        <Box sx={style}>
          <Typography variant="h5" component="h2">
            {teamName}
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Members -
          </Typography>
          <List>
            {member.map((_member, idx) => (
              <ListItem disablePadding>
                Name: {_member?.name}
                <br />
                Email: {_member?.email}
                <br />
                Phone: {_member?.phone}
                <br />
                College: {_member?.college}
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>
    </div>
  );
};

export default AdminModal;
