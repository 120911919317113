import Box from "@mui/material/Box";

const sizeObj = {
  small: {
    width: "350px",
  },
  medium: {
    width: "450px",
  },
  large: {
    width: "550px",
  },
};

const DecorationPice = ({ size, img, imgWidth, position, ...otherStyles }) => {
  return (
    <Box
      component="img"
      src={img}
      className="decoration-piece"
      sx={{
        ...position,
        ...sizeObj[size],
        aspectRatio: "auto",
        ...otherStyles,
      }}
    />
  );
};

export default DecorationPice;
