import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  InputAdornment,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import Form from "react-bootstrap/Form";
import { Wrapper } from "./Styles";
import { customFetchJson } from "../../utils/axios";
import { Loader } from "../../components";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const InputDefault = styled(TextField)({
  "& label.Mui-focused": {
    color: "#2F3C7E",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#8AAAE5", // Change this color
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#317773", // Change this color
    },
    "&:hover fieldset": {
      borderColor: "#E2D1F9", // Change this color
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ADD8E6", // Change this color
    },
  },
  "& input": {
    color: "#FFFFFF", // Change the text color
  },
  "& .MuiFormLabel-root": {
    color: "#FFFFFF", // Change the label text color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#FFFFFF", // Change the focused label text color
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#FFFFFF", // Change the placeholder color
  },
});
const flexContainerStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  // border: "1px solid red",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap", // Allow content to wrap to th next line
};

const responsiveColumn = {
  display: "flex",
  flexBasis: "100%", // Make each item take full width in a column
  padding: "5px", // Adjust spacing as needed
  justifyContent: "space-between",
};

const AdminLogin = () => {
  const navigate = useNavigate();
  const [selectEvent, setSelectedEvent] = useState("");
  const [events, setEvents] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [loading, setLoading] = useState(true);

  const getAllEvents = async () => {
    setLoading(true);
    const resp = await customFetchJson.get("/api/showAllEvents");
    setEvents(
      resp.data.map((e) => {
        return e.name;
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    const adminCred = JSON.parse(sessionStorage.getItem("adminCred"));
    if (adminCred?.eventName || adminCred?.id || adminCred?.password) {
      toast.success("Welcome !");
      navigate("dashboard");
    } else {
      getAllEvents();
    }
  }, []);

  const handleSubmit = async (event) => {
    try {
      setLoading(true);
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const teamObj = {
        eventName: selectEvent,
        // eventName: "ARCHITECTURA: Bid, Visualise, Design",
        id: data.get("admin"),
        password: data.get("password"),
      };
      // console.log("teamobj", teamObj);
      const resp = await customFetchJson.post("/api/adminlogin", teamObj);
      // console.log(resp);
      sessionStorage.setItem("adminCred", JSON.stringify(teamObj));
      toast.success("Welcome !");
      setLoading(false);
      navigate("dashboard");
    } catch (error) {
      setLoading(false);
      console.log(error.response.data.msg);
      toast.error(error.response.data.msg || "Something went wrong! ");
    }
  };

  if (loading) return <Loader />; // Not working

  return (
    <Wrapper>
      <div>
        <div className="m-3" style={{ opacity: "1", top: "0", padding: "0" }}>
          <div style={flexContainerStyle}>
            <Typography variant="h3" className="mb-3 " align="center">
              Event Credentials
            </Typography>

            <Form className="m-3" onSubmit={handleSubmit}>
              {/* <div style={responsiveColumn}> */}
              <Grid
                container
                spacing={2}
                sx={{ width: "100%", p: 1, color: "black" }}
              >
                <Grid item xs={12} sm={12} lg={12} sx={{ p: 1 }}>
                  <FormControl fullWidth value={selectEvent}>
                    <InputLabel id="demo-simple-select-label">
                      Select Event
                    </InputLabel>
                    <Select
                      value={selectEvent}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      style={{ color: "white" }}
                      label="Select event"
                      onChange={(e) => setSelectedEvent(e.target.value)}
                      name="event"
                      required
                      sx={{ color: "black !important" }}
                    >
                      {events.map((event, index) => (
                        <MenuItem key={index} value={event}>
                          {event}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* </Grid> */}
                {/* </div> */}
                <Grid item xs={12} sm={12} lg={12} sx={{ p: 1 }}>
                  <TextField
                    name="admin"
                    label="Admin Id"
                    placeholder="XYZ"
                    type="text"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} sx={{ p: 1 }}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      name="password"
                      label="Password"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                className="m-3"
                variant="contained"
                type="submit"
                sx={{ transform: "none", left: "0" }}
              >
                Register
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default AdminLogin;
