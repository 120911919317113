import styled from "@emotion/styled";
import Box from "@mui/material/Box";
// import eventbg from "../../assets/eventbg.webp"
// import eventbg3 from "../../assets/eventbg3.jpg";
export const Wrapper = styled(Box)({
  ".body": {
    // backgroundImage: `url(${eventbg2})`,
    // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url(${eventbg3})`,
    // backgroundImage: `url(${externalImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    // backgroundColor: "rgba(0, 0, 0, 0.2)",
    backgroundBlendMode: "multiply",
    height: "100vh",
  },
  ".text-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    fontFamily: `"ReFormationSansRegular", sans-serif !important`,
    fontWeight: "650",
    fontSize: "clamp(2em,1em + 5vw,6em)",
    position: "absolute",
    width: "100vw",
    color: "white",
    left: "50%",
    top: "50%",
    WebkitTransform: "Translate(-50%,-50%)",
    transform: "Translate(-50%,-50%)",
  },
  ".content": {
    opacity: "0",
    animation: "revealText 1s ease forwards",
    animationDelay: "0.8s",
  },
  ".logotext": {
    // opacity: 0,
    animation: "revealC 1s linear forwards",
    // animationDelay: "1s",
  },
  "@media only screen and (max-width: 800px)": {
    ".text-container": {
      fontWeight: "600",
      // fontSize: "60px",
    },
    ".logotext": {
      width: "80px",
    },
  },
});