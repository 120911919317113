import styled from "@emotion/styled";
import Box from "@mui/material/Box";

export const Wrapper = styled(Box)({
  "@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap')":
    {},
  "@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800;900&display=swap')":
    {},
  "*": {
    fontFamily: "Nunito, sans-serif",
  },

  ".grid": {
    backgroundSize: "cover",
    backgroundRepeat: "repeat",
    margin: "150px",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gap: "30px",
    justifyItems: "center",
    alignItems: "center",

    "@media (max-width: 768px)": {
      gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    },

    "@media (max-width: 480px)": {
      gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
    },
  },

  ".cards": {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    margin: "auto",
    padding: 0,
    marginTop: "15px",
    alignItems: "center",
    justifyContent: "center",
  },
  ".cards_item": {
    display: "flex",
    padding: "1rem",
    // border: "2px solid #000",
    alignItems: "center",
    justifyContent: "center",
  },
  "@media (min-width: 40rem)": {
    ".cards_item": {
      width: "50%",
    },
  },
  "@media (min-width: 60rem)": {
    ".cards_item": {
      width: "33.3333%",
    },
  },
  body: {
    color: "#272727",
    fontFamily: "'Quicksand', serif",
    fontStyle: "normal",
    fontWeight: 400,
    letterSpacing: 0,
    padding: "1rem",
  },
  ".main": {
    maxWidth: "1200px",
    margin: "0 auto",
  },

  ".card10": {
    // filter: 'drop-shadow(rgb(0, 0, 0,0.8) 0px 0px 0.1px)',
    // filter: 'drop-shadow(rgb(0, 0, 0,0.8) 0px 0px 5px)',
  },
  ".card10 *": {
    fontFamily: '"Jost", sans-serif',
  },
  ".card10 .card-main": {
    backgroundColor: "#141313c2",
    boxShadow: "0 0 10px rgba(220, 220, 220, 0.716)!important",
    background: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    flexDirection: "column",
    width: "330px",
    height: "475px",
    clipPath:
      "polygon(0 0, 60% 0, calc(60% + 10px) 10px, 90% 10px, calc(90% + 10px) 0, 100% 0, 100% 35%, calc(100% - 10px) calc(35% + 10px), calc(100% - 10px) 70%, 100% calc(70% + 10px), 100% calc(100% - 30px), calc(100% - 30px) 100%, 0 100%, 0 82%, 10px calc(82% - 10px), 10px calc(25%), 0 calc(25% - 10px))",
    transition: "all 0.2s linear",

    // filter: 'drop-shadow(rgb(255, 255, 255) 0px 0px 1px)',
  },
  ".card10 .card-main:hover": {
    transform: "scale(1.05)",
  },
  ".card10 .card-img-shadow": {
    filter: "drop-shadow(rgb(255, 255, 255,0.8) 0px 0px 10px)",
  },

  ".card10 .card-img-shadow img": {
    filter: "drop-shadow(rgb(255, 0, 0) 0px 0px 10px)",
    width: "100%",
    height: "200px",
    objectFit: "cover",
    marginBottom: "25px",
    WebkitClipPath:
      "polygon(0 0, 100% 0, 100% 100%, 30px 100%, 0 calc(100% - 30px))",
    clipPath: "polygon(0 0, 100% 0, 100% 100%, 30px 100%, 0 calc(100% - 30px))",
  },
  ".card10 .card-content": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "0px 30px",
    paddingBottom: "20px",
  },
  ".card10 h2": {
    fontFamily: `"ReFormationSansRegular", sans-serif !important`,
    color: "#fff",
    fontSize: "22px",
    margin: "0.3rem 0",
    justifyContent: "center",
    textTransform: "uppercase",
    textAlign: "center",
  },
  ".card10 p": {
    fontFamily: "Georgia, serif",
    flex: "1",
    // margin: '1px 0px',
    // paddingTop:"0rem",
    lineHeight: "auto",
    margin: "0rem",
    letterSpacing: "1px",
    color: "white",
    // maxWidth: '1200px',
  },
  ".card10 .button-shadow": {
    filter: "drop-shadow(rgb(255, 255, 255) 0px 0px 5px)",
    transition: "all 0.2s ease-in",
  },
  ".card10 .button-shadow:hover": {
    filter: "drop-shadow(rgb(255, 255, 255) 0px 0px 10px)",
  },
  ".card10 .card-button": {
    width: "100%",
    border: "none",
    textAlign: "center",
    marginTop: "auto",
    padding: "10px 0px",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    WebkitClipPath:
      "polygon(0 0, 100% 0%, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)",
    clipPath:
      "polygon(0 0, 100% 0%, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)",
    color: "green",
    fontWeight: "600",
    transition: "all 0.2s ease-in",
  },
  ".card10 .card-button:hover": {
    color: "white",
  },
  "@keyframes opacity": {
    "0%": {
      borderRight: "1px solid transparent",
    },
    "10%": {
      borderRight: "1px solid #bd9f67",
    },
    "80%": {
      borderRight: "1px solid #bd9f67",
    },
    "100%": {
      borderRight: "1px solid transparent",
    },
  },
  "@keyframes trail": {
    "0%": {
      background:
        "linear-gradient(90deg, rgba(189, 159, 103, 0) 90%, rgba(189, 159, 103, 1) 100%)",
      opacity: 0,
    },
    "30%": {
      background:
        "linear-gradient(90deg, rgba(189, 159, 103, 0) 70%, rgba(189, 159, 103, 1) 100%)",
      opacity: 1,
    },
    "70%": {
      background:
        "linear-gradient(90deg, rgba(189, 159, 103, 0) 70%, rgba(189, 159, 103, 1) 100%)",
      opacity: 1,
    },
    "95%": {
      background:
        "linear-gradient(90deg, rgba(189, 159, 103, 0) 90%, rgba(189, 159, 103, 1) 100%)",
      opacity: 0,
    },
  },
  filterButtonsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    marginTop: "50px",
  },

  filterButtons: {
    background: "rgba(0, 0, 0, 0.8)",
    backdropFilter: "blur(10px)",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    borderRadius: "10px",
    padding: "20px",
    width: "fit-content",
  },

  button: {
    padding: "10px 20px",
    fontSize: "16px",
    width: "100%",
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    border: "none",
    color: "white",
    borderRadius: "5px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    transitionDuration: "0.4s",
    backdropFilter: "blur(10px)",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },

  buttonHover: {
    backgroundColor: "rgba(0, 120, 0, 0.4)",
    color: "black",
    border: "1px solid #4caf50",
  },

  //button
  ".depclubButton": {
    // border: "1px solid red",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "120px",
    marginBottom: "0px",

    button: {
      margin: "0 5px", // Adjusted margin for buttons
      width: "120px", // Added max width for buttons
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      color: "white",
      borderRadius: "5px",
      transitionDuration: "0.4s",
      // Additional styles for buttons
      "&.active": {
        backgroundColor: "green",
        color: "white",
      },
    },

    "@media (max-width: 700px)": {
      gap: "1em",
      button: {
        margin: "0 3px",
        width: "max-content",
      },
      flexDirection: "column",
    },
  },
});
