import axios from "axios";

export const customFetchJson = axios.create({

  baseURL: process.env.REACT_APP_DB_URL,
  // baseURL: "http://localhost:8000",
  headers: {
    "Content-Type": "application/json",
  },
});

export const customFetchMultipart = axios.create({
  baseURL: process.env.REACT_APP_DB_URL,
  // baseURL: "http://localhost:8000",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});


