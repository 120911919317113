import styled from "@emotion/styled";
import Box from "@mui/material/Box";

export const Wrapper = styled(Box)({
  "#canvas_container2": {
    overflowX: "hidden",
    height: "100% !important",
    userSelect: "none",
    position: "relative",
    textAlign: "center",
    marginTop: "9%",
  },
  ".hostel": {
    display: "flex",
    justifyContent: "space-between",
  },

  ".padd": {
    paddingTop: "20px",
  },

  ".tshirt-carousel": {
    marginTop: "5em",
    width: "40%",
  },

  ".tshirt-carousel div": {
    maxHeight: "600px",
  },

  ".tshirt-carousel-img img": {
    maxHeight: "370px !important",
  },

  "@media screen and (max-width: 980px)": {
    ".tshirt-carousel": {
      marginTop: "1em",
      width: "90%",
    },
  },
  ".custom-file-upload": {
    border: "1px solid #317773",
    borderRadius: "4px",
    padding: "1em",
    /* width: 48%; */
    width: "100%",
    overflow: "hidden",
  },

  ".custom-file-upload:hover": {
    borderColor: "white",
  },
  "@media screen and (max-width: 800px)": {
    "#canvas_container2": {
      marginTop: "14%",
    },
  },
  ".date": {
    width: "100%",
    // backgroundColor:"white",
    "& label.Mui-focused": {
      color: "#2F3C7E",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#8AAAE5", // Change this color
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#317773", // Change this color
      },
      "&:hover fieldset": {
        borderColor: "#E2D1F9", // Change this color
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ADD8E6", // Change this color
      },
    },
  },
});
