import { useState, useEffect } from "react";
import { Wrapper } from "./Styles";
import { Box } from "@mui/material";
import EventCard from "./eventCard";
import { customFetchJson } from "../../utils/axios";
import { Loader, SearchBar } from "../../components";
import toast from "react-hot-toast";

const Events = () => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [originalEvents, setOriginalEvents] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchEvents = async () => {
    try {
      setLoading(true);
      const resp = await customFetchJson("/api/showAllEvents");
      setEvents(resp.data);
      setOriginalEvents(resp.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error while fetching events !");
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const filterCards = (category) => {
    if (category !== null) {
      const filteredEvents = originalEvents.filter(
        (event) => event.type === parseInt(category)
      );
      setEvents(filteredEvents);
    } else {
      setEvents(originalEvents);
    }
    setActiveCategory(category);
  };

  const handleSearch = () => {
    const filteredEvents = originalEvents.filter(
      (event) =>
        event.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        event.organizer.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setEvents(filteredEvents);
    setActiveCategory(null);
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  if (loading) return <Loader />;
  
  return (
    <Wrapper>
      <Box className="main">
        <Box className="depclubButton">
          <Box sx={{ display: "flex" }}>
            <button
              onClick={() => filterCards(null)}
              className={activeCategory === null ? "active" : ""}
            >
              All
            </button>
            <button
              onClick={() => filterCards("1")}
              className={activeCategory === "1" ? "active" : ""}
            >
              Club
            </button>
            <button
              onClick={() => filterCards("2")}
              className={activeCategory === "2" ? "active" : ""}
            >
              Department
            </button>
            <button
              onClick={() => filterCards("3")}
              className={activeCategory === "3" ? "active" : ""}
            >
              Informal
            </button>
          </Box>
          <Box>
            <SearchBar text={searchTerm} setText={setSearchTerm} />
          </Box>
        </Box>
        <ul className="cards">
          {events.map((event, index) => (
            <EventCard
              key={index}
              eventId={event._id}
              imgSrc={event.posterMobile}
              title={event.name}
              prizes={event.prizes}
              organizer={event.organizer}
              venue={
                event.stages.length > 0
                  ? event.stages[0].venue
                  : "No venue specified"
              }
              category={event.type.toString()}
              isInformal={event.type.toString() === "3"} // true for informal, false for others
            />
          ))}
        </ul>
      </Box>
    </Wrapper>
  );
};

export default Events;
