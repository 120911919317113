import React from "react";
// import Threejstshirt from  '../Tshirt/Threejstshirt';
import Navbar from "../../components/Navbar/Navbar";

import { Wrapper } from "./styles";
import { Box, Typography } from "@mui/material";
import logo from "../../assets/concettoLogo40x40.png";
const EventNotExist = () => {
  return (
    <Wrapper>
      <Navbar />
      <Box className="body">
        <center>
          <div className="text-container">
            <div className="content">This event doesn't exists.</div>
          </div>
        </center>
      </Box>
    </Wrapper>
  );
};

export default EventNotExist;