import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
// import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import concettoLogo from "../../assets/concettoLogo40x40.png";
import Wrapper from "./styles";
import scrollToSponsor from "../../utils/scrollToSponsors";
import MobileLink from "./MobileLink";
// import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import one from "../../assets/google-play-badge.png";
const StyledDrawer = styled(Drawer)({
  ".bottomBar": {
    borderTop: "1px solid #fff",
    zIndex: "9999",
    background: "black",
    transition: "all 0.3s ease",
    color: "#fff",
  },
});

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const window = document.body;
  // const drawerWidth = document.body.clientWidth;
  // const navItems = [
  //   { name: "Sponsors", url: "/#targetSection" },
  //   { name: "Merchandise", url: "/tshirt" },
  // ];
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleSponsorScroll = () => {
    if (location.pathname !== "/") return navigate("/");
    scrollToSponsor();
  };

  return (
    <Wrapper>
      {/* <section className="navbar"> */}
      <div className="d-center">
        <Box sx={{ display: "flex" }} className="d-center">
          <CssBaseline />
          <AppBar component="nav" className="navbar">
            <Toolbar className="w-full toolbar">
              <IconButton
                color="white"
                aria-label="open drawer"
                edge="start"
                className="postion"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                {mobileOpen ? (
                  <CloseIcon className="white" />
                ) : (
                  <MenuIcon className="white" />
                )}
              </IconButton>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" component="div" sx={{ color: "#fff" }}>
                  <Button component={Link} to="/">
                    <img
                      src={concettoLogo}
                      className="logo"
                      alt="Concetto"
                    ></img>
                  </Button>
                </Typography>

                <Box
                  sx={{
                    display: {
                      xs: "none",
                      md: "flex",
                    },
                    flexGrow: 1,
                    justifyContent: "space-around",
                  }}
                >
                  <Button component={Link} className="nav-link" to="/events">
                    Events
                  </Button>
                  <Button
                    component="a"
                    className="nav-link"
                    href="https://forms.gle/J5f4kswgcTCcmLB78"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Accommodation
                  </Button>
                  <Button
                    component="a"
                    className="nav-link"
                    href="https://linktr.ee/Concetto_Workshops"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Workshops
                  </Button>
                  <Button className="nav-link" onClick={handleSponsorScroll}>
                    Sponsors
                  </Button>
                  {/* <Button component={Link} className="nav-link" to="/tshirt">
                    Merchandise
                  </Button> */}
                  <Button component={Link} className="nav-link" to="/aboutUs">
                    About Us
                  </Button>
                  <Button component={Link} className="nav-link" to="/teams">
                    Teams
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      md: "block",
                    },
                  }}
                >
                  <IconButton
                    color="inherit"
                    component="a"
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.iitism.concetto&pli=1"
                    className="playStoreIcon"
                  >
                    <img
                      src={one}
                      alt="Google Play Store"
                      className="playStoreIcon"
                    />
                  </IconButton>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
          <nav>
            <StyledDrawer
              container={window}
              variant="temporary"
              open={mobileOpen}
              anchor={"bottom"}
              className="bottomBar"
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: "100vw",
                  height: "100vh",
                  background: "#171717",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                },
              }}
            >
              <Box
                onClick={handleDrawerToggle}
                sx={{
                  textAlign: "center",
                }}
              >
                {/* <Divider /> */}
                <List>
                  <MobileLink url="/events" text="Events" />
                  <MobileLink
                    text="Accommodation"
                    component="a"
                    href="https://forms.gle/J5f4kswgcTCcmLB78"
                    target="_blank"
                    rel="noreferrer"
                  />
                  <MobileLink
                    text="Workshops"
                    component="a"
                    href="https://linktr.ee/Concetto_Workshops"
                    target="_blank"
                    rel="noreferrer"
                  />
                  <ListItem
                    disablePadding
                    sx={{ borderBottom: "1px solid white" }}
                  >
                    <ListItemButton
                      sx={{
                        textAlign: "center",
                        "&:hover": { color: "#f7f75d" },
                      }}
                      className="mobile-nav-links"
                      onClick={handleSponsorScroll}
                    >
                      <ListItemText
                        className="mobile-nav-links"
                        primaryTypographyProps={{
                          fontSize: "1.5em",
                          fontFamily: `"ReFormationSansRegular", sans-serif !important`,
                        }}
                        primary="Sponsors"
                      />
                    </ListItemButton>
                  </ListItem>
                  {/* <MobileLink url="/tshirt" text="Merchandise" /> */}
                  <MobileLink url="/aboutUs" text="About Us" />
                  <MobileLink url="/teams" text="Teams" noBorder />
                </List>
              </Box>
            </StyledDrawer>
          </nav>
        </Box>
      </div>
    </Wrapper>
  );
};

export default Navbar;
