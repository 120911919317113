import styled from "@emotion/styled";
import Box from "@mui/material/Box";

export const Wrapper = styled(Box)({
  "*": {
    before: { boxSizing: "border-box" },
    after: { boxSizing: "border-box" },
    boxSizing: "border-box",
    // fontFamily: "Nunito, sans-serif",
  },
  global: {
    "*": {
      boxSizing: "border-box",
    },
  },
  "@media (max-width: 1200px)": {
    ".text-blk.section-head": {
      fontSize: "100px",
      lineHeight: "55px",
    },
    ".text-blk.section-text": {
      fontSize: "1em",
      lineHeight: "30px",
    },
  },
  mediaQueries: {
    "@media (max-width: 1024px)": {
      responsiveCellBlock: { minHeight: "75px" },
      responsiveContainerBlock: { flexDirection: "column-reverse" },
      container: {
        marginTop: "50px",
        marginRight: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
      },
    },
    "@media (max-width: 768px)": {
      textBlkSectionText: { fontSize: "35px" },
      textBlkSectionHead: { fontSize: "40px", lineHeight: "45px" },
    },
    "@media (max-width: 500px)": {
      textBlkSectionHead: { fontSize: "30px", lineHeight: "35px" },
      textBlkSectionText: { fontSize: "16px", lineHeight: "24px" },
      container: {
        marginTop: "40px",
        marginRight: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
      },
    },
  },
  ".responsive-cell-block": { minHeight: "75px" },
  a: {
    "text-decoration-line": "none",
    "text-decoration-thickness": "initial",
    "text-decoration-style": "initial",
    "text-decoration-color": "initial",
    color: "inherit",
  },
  ".text-blk": { lineHeight: "25px" },
  ".responsive-container-block": {
    minHeight: "75px",
    height: "fit-content",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: "20px",
  },
  ".responsive-container-block.container": {
    maxWidth: "1320px",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    marginTop: "80px",
  },
  ".responsive-container-block.big-container": {
    justifyContent: "center",
    paddingTop: "0px",
    paddingRight: "50px",
    paddingBottom: "0px",
    paddingLeft: "50px",
    overflowX: "hidden",
    overflowY: "hidden",
    position: "relative",
    zIndex: 1,
    borderRadius: "15px",
    padding: "20px",
  },
  ".text-blk.section-text": {
    color: "rgb(147, 147, 147)",
    fontSize: "1em",
    maxWidth: "900px",
    marginTop: "25px",
    lineHeight: "27px",
  },
  ".section-head": {
    maxWidth: "700px",
    fontSize: "clamp(3em,1em + 4vw,6em)",
    lineHeight: "50px",
    fontWeight: 700,
    marginBottom: "10px",
    fontFamily: `"ReFormationSansRegular", sans-serif !important`,
  },
  ".text-blk.section-text": {
    // 'max-width': '750px',
    fontSize: "1.3em",
    lineHeight: "35px",
    letterSpacing: "1.1px",
    // 'font-weight': 700,
    // 'margin-bottom': '10px',
  },

  ".responsive-cell-block.wk-ipadp-6.wk-tab-12.wk-mobile-12.wk-desk-6.content-one":
    {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  ".responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-6.wk-ipadp-12.img-one":
    {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      maxHeight: "300px",
      height: "100%",
      alignItems: "center",
    },
  ".responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-6.wk-ipadp-12.content-one":
    {
      display: "flex",
      flexDirection: "column",
      alignItems: "center !important",
      textAlign: "center !important",
    },

  //mobile view

  "@media (max-width: 500px)": {
    ".responsive-container-block.big-container": {
      paddingRight: "0px",
      paddingLeft: "0px",
    },
    ".text-blk.section-head": {
      fontSize: "40px",
      lineHeight: "35px",
    },
    ".text-blk.section-text": {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },

  ".html": {
    position: "relative",
    overflowX: "hidden!important",
  },

  ".body": {
    fontFamily: "'Quicksand', sans-serif",
    color: "white",
  },

  a: {
    textDecoration: "none",
  },

  ".icon": {
    display: "inline-block",
    width: "1em",
    height: "1em",
    strokeWidth: 0,
    stroke: "currentColor",
    fill: "currentColor",
  },

  ".wrapper": {
    width: "100%",
    height: "auto",
    minHeight: "100vh",
    padding: "50px 20px",
    paddingTop: "100px",
    display: "flex",
    backgroundImage:
      "linear-gradient(-20deg, #ff2846 0%, #6944ff 100%), url('https://images.unsplash.com/photo-1445905595283-21f8ae8a33d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2073&q=80') center/cover no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "scroll", // Remove fixed attachment
    position: "relative",
    // "&:hover": {
    //   ".profile-card__img img": {
    //     transform: "scale(1.3)",
    //   },
    // },
  },

  ".media-queries-wrapper": {
    "@media screen and (max-width: 768px)": {
      ".wrapper": {
        height: "auto",
        minHeight: "100vh",
        paddingTop: "100px",
      },
    },
  },

  ".profile-card": {
    width: "100%",
    minHeight: "460px",
    margin: "auto",
    boxShadow: "0px 8px 60px -10px rgba(13,28,39,0.6)",
    background: "rgba(255, 255, 255, 0.15)",
    borderRadius: "12px",
    maxWidth: "1000px",
    position: "relative",
    backdropFilter: "blur(5px) saturate(0.5)",
    border: "1px solid rgba(0, 0, 0, 0.8)",
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",

    "&:hover": {
      ".profile-card__img img": {
        transform: "scale(1.1)",
      },
    },
  },

  ".profile-card-active": {
    ".profile-card.active .profile-card__cnt": {
      filter: "blur(6px)",
    },
    ".profile-card.active .profile-card-message, .profile-card.active .profile-card__overlay":
      {
        opacity: 1,
        pointerEvents: "auto",
        transitionDelay: ".1s",
      },
    ".profile-card.active .profile-card-form": {
      transform: "none",
      transitionDelay: ".1s",
    },
  },

  ".profile-card__img": {
    width: "200px",
    height: "200px",
    marginLeft: "auto",
    marginRight: "auto",
    transform: "translateY(-50%)",
    borderRadius: "50%",
    overflow: "hidden",
    position: "relative",
    zIndex: 4,
    // 'box-shadow': '0px 5px 50px 0px rgb(0, 120, 0), 0px 0px 0px 7px rgba(0, 0, 0, 0.1)',
  },

  ".media-queries-profile-card-img": {
    "@media screen and (max-width: 576px)": {
      ".profile-card__img": {
        width: "120px",
        height: "120px",
      },
    },
  },

  ".profile-card__img img": {
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "50%",
    transition: "transform 400ms ease",
  },

  ".profile-card__cnt": {
    marginTop: "-35px",
    textAlign: "center",
    padding: "0 20px",
    paddingBottom: "40px",
    transition: "all .3s",
  },

  ".profile-card__name": {
    fontWeight: 700,
    fontSize: "40px",
    color: "#fff",
    marginBottom: "15px",
  },

  ".profile-card__txt": {
    fontSize: "18px",
    // 'font-weight': 200,
    color: "#fff",
    marginBottom: "15px",
  },

  ".profile-card__txt strong": {
    // 'font-weight': 700,
  },

  ".profile-card-loc": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // 'font-size': '20px',
    // 'font-weight': 600,
  },

  ".profile-card-loc__icon": {
    display: "inline-flex",
    // 'font-size': '27px',
    marginRight: "10px",
  },

  ".profile-card-inf": {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "flex-start",
    marginTop: "35px",
  },

  ".profile-card-inf__item": {
    padding: "10px 35px",
    minWidth: "150px",
  },

  ".media-queries-profile-card-inf-item": {
    "@media screen and (max-width: 768px)": {
      ".profile-card-inf__item": {
        padding: "10px 20px",
        minWidth: "120px",
      },
    },
  },

  ".profile-card-inf__title": {
    // 'font-weight': 900,
    // 'font-size': '40px',
    color: "#fff",
  },

  ".profile-card-inf__txt": {
    // 'font-weight': 500,
    marginTop: "7px",
  },

  ".profile-card-loc__txt": {
    // 'font-weight': 600,
    fontSize: "20px",
    letterSpacing: "1.05px",
    lineHeight: "30px",
    color: "#fff",
    // textAlign: "justify",
  },
});
