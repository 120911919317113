import styled from "@emotion/styled";
import Box from "@mui/material/Box";

export const Wrapper = styled(Box)({
  ".sponsors": {
    padding: "75px 0",
    paddingBottom: "20px",
    background: "rgb(0, 0, 0)",
    color: "white",
    fontFamily: 'Genos'
  },
  ".sponsors h2": {
    marginBottom: "50px"
  },
  ".section-header":{
    fontFamily:"ReFormationSansRegular",
    textAlign:"center",
    fontSize:"60px",
    fontWeight: 700,
    letterSpacing: "-2px",
    color:"#54bd3d",
    textTransform:"uppercase",
    "@media (max-width:560px)":{
      fontSize:"40px"
    }
  },

  ".true":{
    backgroundColor: "rgb(237, 237, 237)",
  },
  
  ".sponsors-list": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "80px 80px",
    marginBottom: "0",
  },
  ".sponsors-list a": {
    margin: "0 30px 30px",
    height: "135px"
  },
  
  "@media only screen and (max-width: 980px)": {
    ".sponsors-list a": {
      margin: "0 15px",
      height: "95px",
    }
  },
  
  ".sponsors-list a img": {
    padding: "20px 0",
    height: "100%",
    opacity: "1",
    transition: "opacity 0.2s ease",
  },
  
  ".sponsors-list a:hover img": {
    opacity: "0.55"
  }
});
