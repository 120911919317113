import Navbar from "../../components/Navbar/Navbar";
import Sponsors from "../../components/Sponsors/Sponsors";
import Footer from "../../components/footer/Footer";
import Timeline from "../../components/Timeline";

const Home = () => {
  return (
    <div>
      <div id="targetSection">
        <Navbar />
        <Timeline />
        <Sponsors />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
