import styled from "@emotion/styled";
import Box from "@mui/material/Box";

export const Wrapper = styled(Box)({
  "#canvas_container2": {
    overflowX: "hidden",
    height: "100% !important",
    userSelect: "none",
    position: "relative",
    textAlign: "center",
    marginTop: "9%",
  },
  ".hostel": {
    display: "flex",
    justifyContent: "space-between",
  },

  ".padd": {
    paddingTop: "20px",
  },

  ".tshirt-carousel": {
    marginTop: "0em",
    width: "45%",
  },

  ".tshirt-carousel div": {
    maxHeight: "480px",
  },

  ".tshirt-carousel-img img": {
    maxHeight: "450px !important",
  },

  "@media screen and (max-width: 980px)": {
    ".tshirt-carousel": {
      marginTop: "1em",
      width: "90%",
    },
  },
  ".custom-file-upload": {
    border: "1px solid #317773",
    borderRadius: "4px",
    padding: ".65em",
    /* width: 48%; */
    height:"3.5em",
    width: "100%",
    overflow: "hidden",
  },
".toe":{
  border: "1px solid",
borderColor:"#317773",
height:"3.5em",
borderRadius:"4px",
margin:"2px",
},
".date":{
width:"100%",
// backgroundColor:"white",
"& label.Mui-focused": {
  color: "#2F3C7E",
},
"& .MuiInput-underline:after": {
  borderBottomColor: "#8AAAE5", // Change this color
},
"& .MuiOutlinedInput-root": {
  "& fieldset": {
    borderColor: "#317773", // Change this color
  },
  "&:hover fieldset": {
    borderColor: "#E2D1F9", // Change this color
  },
  "&.Mui-focused fieldset": {
    borderColor: "#ADD8E6", // Change this color
  },
},
"& input": {
  color: "#FFFFFF", // Change the text color
},
"& .MuiFormLabel-root": {
  color: "#FFFFFF", // Change the label text color
},
"& .MuiInputLabel-root.Mui-focused": {
  color: "#FFFFFF", // Change the focused label text color
},
"& .MuiInputBase-input::placeholder": {
  color: "#FFFFFF", // Change the placeholder color
},
},
  ".custom-file-upload:hover": {
    borderColor: "white",
  },
  ".formdiv":{
    width:"70%",
  },
  "@media screen and (max-width: 800px)":{
    "#canvas_container2":{
      marginTop:"14%",
    },
    ".formdiv":{
      width:"100%",
    },
    
  },
  ".MuiSvgIcon-root":{
    color:"white",
  },
 


});
