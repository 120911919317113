import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './styles.css';

const events = [
    {
        "date": "12th October, 2023",
        "time": "6 PM-8 PM",
        "event": "INAUGURATION",
        "venue": ""
    },
    {
        "date": "12th October, 2023",
        "time": "9 PM-12 AM",
        "event": "PUBG /CODEM",
        "venue": ""
    },
    {
        "date": "",
        "time": "",
        "event": "",
        "venue": ""
    },
    {
        "date": "13th October, 2023",
        "time": "9AM - 12PM",
        "event": "Sky Is The Limit",
        "venue": "Amber Ground"
    },
    {
        "date": "13th October,2023",
        "time": "10AM -12 PM",
        "event": "Electrodesign Battle(R1+R2)",
        "venue": "NLHC"
    },
    {
        "date": "13th October, 2023",
        "time": "10AM Onwards",
        "event": "Laser Maze",
        "venue": "NVCTI"
    },
    {
        "date": "13th October, 2023",
        "time": "10AM-11 AM",
        "event": "Survival Of The Chemist 2.0(R1)",
        "venue": "NLHC"
    },
    {
        "date": "13th October, 2023",
        "time": "11AM - 12PM",
        "event": "Architectura(R1)",
        "venue": "NLHC"
    },
    {
        "date": "13th October, 2023",
        "time": "2PM-5 PM",
        "event": "Cosmic Cycle Challenge(R1)",
        "venue": "Mech Workshop"
    },
    {
        "date": "13th October, 2023",
        "time": "2PM-4 PM",
        "event": "Green Energy Enigma",
        "venue": ""
    },
    {
        "date": "13th October, 2023",
        "time": "2PM- 3:30PM",
        "event": "Sim-Osmic(R1)",
        "venue": "NLHC"
    },
    {
        "date": "13th October, 2023",
        "time": "2PM-3:30PM",
        "event": "MathBee",
        "venue": "NLHC 1,2,3,4"
    },
    {
        "date": "13th October, 2023",
        "time": "3PM -5PM",
        "event": "Vijendra Chauhan",
        "venue": "PENMAN AUDITORIUM"
    },
    {
        "date": "13th October, 2023",
        "time": "5PM- 7PM",
        "event": "Galactic Maze Maverick",
        "venue": "SAC(ROBOISM ROOM)"
    },
    {
        "date": "13th October, 2023",
        "time": "7:30PM-9PM",
        "event": "Comedy Night",
        "venue": "Amber Ground"
    },
    {
        "date": "13th October, 2023",
        "time": "9PM-11PM",
        "event": "Band Night",
        "venue": "Amber Ground"
    },
    {
        "date": "13th October, 2023",
        "time": "9:00-12PM",
        "event": "Trailblaze",
        "venue": ""
    },
    {
        "date": "",
        "time": "",
        "event": "",
        "venue": ""
    },
    {
        "date": "14th October,2023",
        "time": "12AM to 6PM",
        "event": "Cosmolligence(R1)",
        "venue": "Library"
    },
    {
        "date": "14th October,2023",
        "time": "9AM Onwards",
        "event": "Cosmic Cycle Challenge(R2)",
        "venue": "Mech Workshop"
    },
    {
        "date": "14th October,2023",
        "time": "9AM-11AM",
        "event": "Sailing In Simulation",
        "venue": "NLHC"
    },
    {
        "date": "14th October,2023",
        "time": "9AM-11AM",
        "event": "Survival Of Chemist 2.0(R2)",
        "venue": "Chemical Department"
    },
    {
        "date": "14th October,2023",
        "time": "9AM-10:30AM",
        "event": "Electrodesign Battle(R3)",
        "venue": "NLHC"
    },
    {
        "date": "14th October,2023",
        "time": "10AM-12PM",
        "event": "Sandeepan Chattopadhyay",
        "venue": "GJLT"
    },
    {
        "date": "14th October,2023",
        "time": "2PM-4PM",
        "event": "Astromaze",
        "venue": "Mining Department"
    },
    {
        "date": "14th October,2023",
        "time": "2PM-4PM",
        "event": "Bear The Load",
        "venue": "Petro Department"
    },
    {
        "date": "14th October,2023",
        "time": "2PM-3:30PM",
        "event": "Architectura(R2)",
        "venue": "Civil Department"
    },
    {
        "date": "14th October,2023",
        "time": "2PM-4PM",
        "event": "MathBee(R2)",
        "venue": "MNC Department"
    },
    {
        "date": "14th October,2023",
        "time": "3PM-5PM",
        "event": "Srikanth",
        "venue": "Penman Auditorium"
    },
    {
        "date": "14th October,2023",
        "time": "4:30PM-8PM",
        "event": "RoboWar",
        "venue": "OAT"
    },
    {
        "date": "14th October,2023",
        "time": "8PM-9PM",
        "event": "Magic Show",
        "venue": "Penman Auditorium"
    },
    {
        "date": "14th October,2023",
        "time": "9PM-11PM",
        "event": "LITM WTC(Culture Night)",
        "venue": "Amber Ground"
    },
    {
        "date": "14th October,2023",
        "time": "11PM Onwards",
        "event": "E-Sports",
        "venue": "NVCTI"
    },
    {
        "date": "",
        "time": "",
        "event": "",
        "venue": ""
    },
    {
        "date": "15th October,2023",
        "time": "2AM to 2PM",
        "event": "Bizathon",
        "venue": "Library"
    },
    {
        "date": "15th October,2023",
        "time": "9AM-11AM",
        "event": "Infosec workshop",
        "venue": "NAC Hall"
    },
    {
        "date": "15th October,2023",
        "time": "9AM-11AM",
        "event": "Chem-E-Flow",
        "venue": "Chemical Department"
    },
    {
        "date": "15th October,2023",
        "time": "9AM to 10AM",
        "event": "Sim-O-smic(R2)",
        "venue": "NLHC"
    },
    {
        "date": "15th October,2023",
        "time": "10AM to 12PM",
        "event": "Santos Kumar",
        "venue": "Penman Auditorium"
    },
    {
        "date": "15th October,2023",
        "time": "2PM to 4PM",
        "event": "Cosmolligence (R2)",
        "venue": "NLHC"
    },
    {
        "date": "15th October,2023",
        "time": "2PM to 4PM",
        "event": "Galactic Maze Maverick",
        "venue": ""
    },
    {
        "date": "15th October,2023",
        "time": "2PM to 4PM",
        "event": "Galactic Logic Circuit",
        "venue": ""
    },
    {
        "date": "15th October,2023",
        "time": "3PM to 5PM",
        "event": "Anubhav Dubey",
        "venue": "Penman Auditorium"
    },
    {
        "date": "15th October,2023",
        "time": "5PM to 7PM",
        "event": "Bizathon(R2)",
        "venue": "GJLT"
    },
    {
        "date": "15th October,2023",
        "time": "5PM to 7PM",
        "event": "Cosmic Quarry",
        "venue": "NLHC"
    },
    {
        "date": "15th October,2023",
        "time": "5PM to 7PM",
        "event": "MiniCode",
        "venue": "NLHC Labs"
    },
    {
        "date": "15th October,2023",
        "time": "8PM onwards",
        "event": "EDM Night",
        "venue": "Amber Ground"
    }
]

const day0 = []
const day1 = []
const day2 = []
const day3 = []

events.forEach(a => {
    if (a.date == "12th October, 2023") {
        day0.push(
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentArrowStyle={{ borderRight: '7px solid  #ffffff' }}
                date={a.date}
                iconStyle={{ background: '#000000', color: '#000' }}
            >
                <h3 className="vertical-timeline-element-title">{a.event}</h3>
                <h4 className="vertical-timeline-element-subtitle">{a.time}</h4>
                <p>
                    Venue : {a.venue}
                </p>
            </VerticalTimelineElement>
        )
    }
    else if (a.date == "13th October, 2023") {
        day1.push(
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentArrowStyle={{ borderRight: '7px solid  #ffffff' }}
                date={a.date}
                iconStyle={{ background: '#000000', color: '#000' }}
            >
                <h3 className="vertical-timeline-element-title">{a.event}</h3>
                <h4 className="vertical-timeline-element-subtitle">{a.time}</h4>
                <p>
                    Venue : {a.venue}
                </p>
            </VerticalTimelineElement>
        )
    }
    else if (a.date == "14th October,2023") {
        day2.push(
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentArrowStyle={{ borderRight: '7px solid  #ffffff' }}
                date={a.date}
                iconStyle={{ background: '#000000', color: '#000' }}
            >
                <h3 className="vertical-timeline-element-title">{a.event}</h3>
                <h4 className="vertical-timeline-element-subtitle">{a.time}</h4>
                <p>
                    Venue : {a.venue}
                </p>
            </VerticalTimelineElement>
        )
    }
    else if (a.date == "15th October,2023") {
        day3.push(
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentArrowStyle={{ borderRight: '7px solid  #ffffff' }}
                date={a.date}
                iconStyle={{ background: '#000000', color: '#000' }}
            >
                <h3 className="vertical-timeline-element-title">{a.event}</h3>
                <h4 className="vertical-timeline-element-subtitle">{a.time}</h4>
                <p>
                    Venue : {a.venue}
                </p>
            </VerticalTimelineElement>
        )
    }
})

function Timeline() {
    const [Day0, setDay0] = React.useState(true);
    const [Day1, setDay1] = React.useState(false);
    const [Day2, setDay2] = React.useState(false);
    const [Day3, setDay3] = React.useState(false);

    const updateday0 = () => {
        setDay0(true);
        setDay1(false);
        setDay2(false);
        setDay3(false);
    }

    const updateday1 = () => {
        setDay0(false);
        setDay1(true);
        setDay2(false);
        setDay3(false);
    }

    const updateday2 = () => {
        setDay0(false);
        setDay1(false);
        setDay2(true);
        setDay3(false);
    }

    const updateday3 = () => {
        setDay0(false);
        setDay1(false);
        setDay2(false);
        setDay3(true);
    }
    return (
        <div className="timeline">
            <div className="mainContent">
                <center>
                    <p className="title">TIMELINE</p>
                    <button onClick={updateday0}>12th October 2023</button>
                    <button onClick={updateday1}>13th October 2023</button>
                    <button onClick={updateday2}>14th October 2023</button>
                    <button onClick={updateday3}>15th October 2023</button>
                </center>
                <div className="content">
                    <VerticalTimeline>
                        {Day0 && day0}
                        {Day1 && day1}
                        {Day2 && day2}
                        {Day3 && day3}
                    </VerticalTimeline>
                </div>
            </div>
        </div>
    )
}

export default Timeline;