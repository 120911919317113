import { Box } from "@mui/material";
import { Form, AlertDialog, DecorationPice } from "../../components";
import DecorationImg from "../../assets/decoration/ele2.png";

const Tshirt = () => {
  return (
    <Box sx={{ position: "relative" }}>
      <DecorationPice
        img={DecorationImg}
        size="medium"
        position={{ bottom: "5%", left: "1%" }}
        opacity="0.8"
      />
      <AlertDialog />
      <Form />
    </Box>
  );
};

export default Tshirt;
