import { useState } from "react";
import toast from "react-hot-toast";
import {
  Box,
  Typography,
  Chip,
  TextField,
  CircularProgress,
  Button,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import Form from "react-bootstrap/Form";
import { customFetchMultipart } from "../../utils/axios";
import Uu from "../Accommodation/size";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import one from "../../assets/imagestshirt/1.webp";
import five from "../../assets/imagestshirt/5.png";
import seven from "../../assets/imagestshirt/7.webp";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Wrapper } from "./Styles";
const images = [
  { url: one },
  { url: seven },
  { url: five },
];
const options = [
  'EVENT+PRONITE+ACCOMMODATION+MERCHANDISE      -------- 1500/-',
  'EVENT+ACCOMMODATION+MERCHANDISE              -------- 1300/-',
  'EVENT+PRONITE+ACCOMMODATION                  -------- 1300/-',
  'EVENT+PRONITE+MERCHANDISE                    -------- 1000/-',
  'EVENT+PRONITE                                --------- 600/-',
  'PRONITE                                      ----------400/-',
  'EVENT                                        ----------300/-',
];
const options2 = [
  'EVENT+PRONITE+ACCOMMODATION                   -------- 600/-',
  'EVENT+MERCHANDISE                             -------- 600/-',
  'EVENT+ACCOMODATION                            ---------450/-',
  'PRONITE only                                  ---------250/-',
];
// const FormContainer = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(2),
// }));

const InputDefault = styled(TextField)({
  "& label.Mui-focused": {
    color: "#2F3C7E",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#8AAAE5", // Change this color
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#317773", // Change this color
    },
    "&:hover fieldset": {
      borderColor: "#E2D1F9", // Change this color
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ADD8E6", // Change this color
    },
  },
  "& input": {
    color: "#FFFFFF", // Change the text color
  },
  "& .MuiFormLabel-root": {
    color: "#FFFFFF", // Change the label text color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#FFFFFF", // Change the focused label text color
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#FFFFFF", // Change the placeholder color
  },
});

const flexContainerStyle = {
  display: "flex",

  justifyContent: "center",
  flexWrap: "wrap", // Allow content to wrap to th next line
};

const responsiveColumn = {
  display: "flex",
  flexBasis: "100%", // Make each item take full width in a column
  padding: "5px", // Adjust spacing as needed
  justifyContent: "space-between",
};
export default function Accomodation() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [admNo, setAdmNo] = useState("");
  const [email, setEmail] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [branch, setBranch] = useState("");
  const [hostel, setHostel] = useState("");
  const [transId, setTransId] = useState("");
  const [room, setRoom] = useState("");
  const [size, setSize] = useState("");
  const [url, setUrl] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      if (!url) {
        return toast.error("Attach screenshot of the payment !");
      }
      if (email.endsWith("iitism.ac.in")) {
        return toast.error("Enter personal Email Id");
      }

      setLoading(true);
      const formData = new FormData();

      const response = {
        orderID: transId,
        name: name,
        admissionNumber: admNo,
        mobileNumber: mobNo,
        branch: branch,
        tshirtSize: size,
        transactionID: transId,
        hostel: hostel,
        roomNumber: room,
        image: url,
        email,
      };

      for (let prop in response) {
        formData.append(prop, response[prop]);
      }

      // console.log(formData);
      const res = await customFetchMultipart.post(`/api/purchase`, formData);
      // console.log("res = ", res);

      // resetting values
      setName("");
      setAdmNo("");
      setEmail("");
      setMobNo("");
      setBranch("");
      setHostel("");
      setTransId("");
      setRoom("");
      setSize("");
      setUrl("");
      setLoading(false);
      toast.success(res.data.msg);
    } catch (error) {
      setLoading(false);
      console.log("e = ", error);
      toast.error(
        error.response.data.msg || "Something went wrong! Try contacting admins"
      );
      // console.log("error", error);
    }
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    const maxSizeInBytes = 500 * 1024;
    if (file && file.size > maxSizeInBytes) {
      // The selected file exceeds the size limit
      toast.error("Image size exceeds 500KB. Please choose a smaller image.");
      event.target.value = null; // Clear the file input field
    }
    else {
      setUrl(event.target.files[0]);
      console.log(url);
    }
  };
  const datePickerStyles = {
    '& .MuiInputBase-input': {
      color: 'white', // Text color
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white', // Border color
      },
    },
  };
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (event) => {
    setSelectedOptions(event.target.value);
  };

  return (
    <Wrapper>
      <div id="canvas_container2">
        {loading && (
          <Box
            sx={{
              position: "fixed",
              zIndex: "9999999",
              top: "50%",
              height: "100%",
              width: "100vw",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
        <div
          className="m-3"
          id="canvas_box2"
          style={{ opacity: loading ? "0.5" : "1" }}
        >
          <div style={flexContainerStyle}>
            <Box className="tshirt-carousel" sx={{ zIndex: "999" }}>
              <Carousel
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
              >
                {images.map((el, idx) => (
                  <Box key={idx} className="tshirt-carousel-img">
                    <img
                      src={el.url}
                      alt="tshirt"
                      style={{ objectFit: "contain", objectPosition: "center" }}
                    />
                  </Box>
                ))}
              </Carousel>
            </Box>
            <Grid
              className="text-light glass-morphism formdiv"
              style={{ borderRadius: "10px" }}
              item
            >
              <Typography variant="h5" className="mb-3 padd" align="center">
                Enter Your Information
              </Typography>
              <Form className="m-3" onSubmit={submitHandler}>
                <Grid container spacing={2} sx={{ width: "100%", p: 3 }}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <InputDefault
                      label="Name of the Participant"
                      placeholder="XYZ"
                      type="text"
                      fullWidth
                      required
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <InputDefault
                      label="Institute Name"
                      placeholder="IIT Dhanbad"
                      type="text"
                      fullWidth
                      required
                      value={admNo}
                      onChange={(e) => {
                        setAdmNo(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <InputDefault
                      label="Contact Number(Whatsapp)"
                      placeholder="8340XXXXXX"
                      type="tel"
                      fullWidth
                      required
                      value={mobNo}
                      onChange={(e) => {
                        setMobNo(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputDefault
                      label="Institute Id Number"
                      type="text"
                      placeholder="21JEXXXX"
                      fullWidth
                      required
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <label htmlFor="upload-photo" className="custom-file-upload" >
                      <Chip
                        sx={{
                          backgroundColor: "white",
                          color: "black",
                          borderRadius: "4px",
                        }}
                        label="INSTITUTE ID CARD PHOTO"
                      />
                      &nbsp;
                      {url?.name}
                    </label>
                    <input
                      className="tshirt-input"
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <FormControl className="date" fullWidth>
                      <InputLabel id="demo-simple-select-label">Events Participating (Name of the event(s)  you have registered for if any.)</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        value={selectedOptions}
                        // value={age}
                        onChange={handleOptionChange}
                        style={{ color: 'white' }}
                        label="Events Participating Name of the event(s)  you have registered for if any."
                        renderValue={(selected) => (
                          <Grid>
                            {selected.join(',  ')}
                          </Grid>

                        )}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: '400px',
                              maxWidth: '150px',
                            },
                          },
                        }}
                      >
                        {options.map((option, index) => (
                          <MenuItem key={index} value={option} >
                            <input
                              type="checkbox"
                              checked={selectedOptions.indexOf(option) > -1}
                              style={{ width: '15px', height: '15px' }}
                            />
                            <span style={{ fontSize: "1em" }}>{option}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <FormControl className="date" fullWidth>
                      <InputLabel id="demo-simple-select-label">REGISTRATION SLABS FOR 3 Day (if you plan to experience fest for total 3 days)</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={age}
                        style={{ color: 'white' }}
                        label="REGISTRATION SLABS FOR 3 Day (if you plan to experience fest for total 3 days)"
                      // onChange={handleChange}
                      >
                        {options.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <FormControl className="date" fullWidth>
                      <InputLabel id="demo-simple-select-label">REGISTRATION SLABS FOR 1 Day (if you plan to experience fest for total one days)</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={age}
                        style={{ color: 'white' }}
                        label="REGISTRATION SLABS FOR 1 Day (if you plan to experience fest for total one days)"
                      // onChange={handleChange}
                      >
                        {options2.map((option2, index) => (
                          <MenuItem key={index} value={option2}>
                            {option2}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>


                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker className="date" label="Arrival Date" />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6}>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker className="date" label="Departure date" />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6}>
                    <InputDefault
                      label="Enter Amount"
                      type="text"
                      placeholder="500 Rs"
                      fullWidth
                      required
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6}>
                    <InputDefault
                      label="Transaction id"
                      type="text"
                      placeholder="T23091XXXXXXXXX"
                      fullWidth
                      required
                      value={transId}
                      onChange={(e) => {
                        setTransId(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} >
                    <label htmlFor="upload-photo" className="custom-file-upload">
                      <Chip
                        sx={{
                          backgroundColor: "white",
                          color: "black",
                          borderRadius: "4px",
                        }}
                        label="Payment screenshot"
                      />
                      &nbsp;
                      {url?.name}
                    </label>
                    <input
                      className="tshirt-input"
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <Uu size={size} setSize={setSize} />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <Button
                      type="submit"
                      className="m-3"
                      variant="contained"
                      sx={{ transform: "none", left: "0" }}
                    >
                      Place order
                    </Button>
                  </Grid>
                </Grid>


              </Form>
            </Grid>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
