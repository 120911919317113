import DecorationPice from "../../components/DecorationPiece";
import Space1 from "../../assets/decoration/space1.png";
import { Wrapper } from "./Styles";

const AboutUs = () => {
  return (
    <Wrapper>
      <div className="responsive-container-block big-container">
        <div className="responsive-container-block container">
          <div className="responsive-container-block">
            <div className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-6 wk-ipadp-12 content-one">
              <p
                className="section-head"
                style={{
                  color: "#fff",
                  marginBottom: "0.5em",
                  textAlign: "justify",
                }}
              >
                ABOUT US
              </p>

              <p className="text-blk section-text" style={{ color: "#fff" }}>
                CONCETTO is the annual three-day Techno-Management Fest hosted
                by the esteemed Indian Institute of Technology (Indian School of
                Mines), Dhanbad every year. Merging technology and management,
                the fest is a beacon of innovation and budding talent. It has
                the largest participation in Eastern India, with a footfall of
                over 20,000. CONCETTO is more than an event. It's a platform
                where participants take on challenges to test their technical
                skills, expand their horizons with workshops by experts, and
                enjoy electrifying performances by renown stars as the sun sets.
                <br />
                <br />
                This year, CONCETTO'23 - ORBITING INGENUITY is here with the
                theme 'Cosmic Odyssey', which revolves around the Exploration
                and Colonization of Space. From 13th - 15th October 2023 the
                fest will mesmerize you with a plethora of technical and
                managerial events lined up. CONCETTO, a dream of engineering
                enthusiasts, empowers dreams to take flight. Certificates,
                goodies, and prizes worth lakhs await lucky participants. Step
                into the realm where technology and innovation entwine, where
                learning si festive, and each moment weaves cherished memories.
              </p>
            </div>
          </div>
        </div>
      </div>

      <DecorationPice
        img={Space1}
        size="medium"
        position={{ top: 0, right: 0 }}
      />

      <div className="float-container">
        <div className="wrapper">
          <div className="profile-card js-profile-card">
            <div className="profile-card__img">
              <img
                src="https://res.cloudinary.com/dedg75ktl/image/upload/v1696017291/Convener_xcxmfn.jpg"
                alt="profile card"
              />
            </div>

            <div className="profile-card__cnt js-profile-cnt">
              <div className="profile-card__name">
                Prof.Vasanta Govind Kumar Villuri
              </div>
              <div className="profile-card__txt">
                Convener's Message <strong></strong>
              </div>
              <div className="profile-card-loc">
                <span className="profile-card-loc__txt ">
                  As quoted by Edward Teller, "The science of today is the
                  technology of tomorrow." Today's young minds are in eager
                  search of a platform to showcase their talent, and CONCETTO
                  provides such a platform for students. CONCETTO '23: Cosmic
                  Odyssey celebrates the incredible journey of innovation and
                  exploration. This year, under the theme "Cosmic Odyssey," the
                  fest pays homage to India's accomplishments in space
                  exploration and offers a platform where brilliant young minds
                  converge with industries seeking fresh perspectives. This
                  remarkable event acknowledges the dedicated team and sponsors
                  whose invaluable efforts have made it possible. Together,
                  participants embark on a collective quest to reach for the
                  stars, exploring new horizons in technology and innovation.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper">
          <div className="profile-card js-profile-card">
            <div className="profile-card__img">
              <img
                src="https://res.cloudinary.com/dedg75ktl/image/upload/v1696017291/Co_Convener_g6ea6g.jpg"
                alt="profile card"
              />
            </div>

            <div className="profile-card__cnt js-profile-cnt">
              <div className="profile-card__name">Prof.Saurabh Srivastva</div>
              <div className="profile-card__txt">
                Co-Convener's Message <strong></strong>
              </div>
              <div className="profile-card-loc">
                <span className="profile-card-loc__txt">
                  With a sense of purpose, CONCETTO '23 invites you to embark on
                  a journey into the boundless mysteries of the universe.
                  Navigating the cosmos under the theme "Cosmic Odyssey," it
                  pays tribute to India's remarkable achievements in space
                  exploration. This fest is more than a showcase of talent, it's
                  a bridge connecting industries with the next generation of
                  innovators. The event is designed with a diverse range of
                  technical and management events, providing a stage for
                  emerging talent to shine. Our heartfelt thanks to the
                  dedicated team, faculty, organizers, and sponsors who have
                  made this remarkable event possible.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default AboutUs;
