import { Wrapper } from "./styles";
import YourComponent from "../../components/Card2/card";
import { teams } from "../../data/teams";
import Footer from "../../components/footer/Footer";
const Team = () => {

  return (
    <Wrapper>
    <div className="parDiv" >
      <div className="container">
        <h1 className="ttl">Core Team</h1>
      </div>
      <section id="main">
        {teams.map((user, i) => {
          if (user.team !== "Tech Team") {
            return <YourComponent user={user} key={i} />;
          }
          return null;
        })}
      </section>
      <div className="container">
        <h1 className="ttl">Developers</h1>
      </div>
      <section id="main">
        {teams.map((user, i) => {
          if (user.team === "Tech Team") {
            return <YourComponent user={user} key={i} />;
          }
          return null;
        })}
      </section>
      </div>
    </Wrapper>
  );
};

export default Team;
