import { useState, useEffect } from "react";
import "./styles.css";
import { Box } from "@mui/material";
// import { information } from "../data/EventDescriptionData";
// import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { customFetchJson } from "../../utils/axios";
import { useParams, useNavigate } from "react-router-dom";
import { Loader } from "../index";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

function EventDescription() {
  const navigate = useNavigate();
  const { formId } = useParams();
  const [information, setInformation] = useState({});
  const [width, setWidth] = useState();
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const [visible5, setVisible5] = useState(false);
  const [visible6, setVisible6] = useState(false);
  useEffect(() => {
    // scroll to top
    window.scrollTo({ top: 0, behavior: "smooth" });
    customFetchJson
      .get(`/api/showEvents/${formId}`)
      .then((response) => {
        setInformation(response.data[0]);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    setWidth(window.screen.width);
  }, [window.screen.width]);

  if (!information["name"]) return <Loader />;
  else {
    const status = information.registrationStatus;
    let register = () => {};
    if (status == 3) {
      register = () => {
        window.open(information.registrationLink, "_blank", "noreferrer");
      };
    } else {
      register = () => {
        navigate(`/eventregister/${formId}`);
      };
    }
    const task = <div>★ {information.descriptionEvent}</div>;
    const extracontent = [];
    information.extraDetails.forEach((extra) => {
      extracontent.push(
        <div className="title additional_question">{extra["key"]}</div>
      );
      extra["value"].forEach((answer) => {
        extracontent.push(<div className="response1">★ {answer}</div>);
      });
      extracontent.push(<br></br>);
    });
    const coordinators = [];
    information.contacts.forEach((coordinator) =>
      coordinators.push(
        <div className="coordinator">
          <div>{coordinator["name"]}</div>
          <div className="phone">{coordinator["phoneNumber"]}</div>
        </div>
      )
    );
    const stages = [];
    let count = 1;
    information.stages.forEach((stage) => {
      stages.push(
        <div className="eventStage">
          <div>
            <h3>
              <strong>Stage {count}</strong>
            </h3>
          </div>
          <h4>{stage.description}</h4>
          <div className="questions">
            <center>
              <span className="title">VENUE </span>
              <span className="response">{stage.venue}</span>
            </center>
          </div>
          <div className="questions">
            <center>
              <span className="title">TIME </span>
              <span className="response">{stage.timing}</span>
            </center>
          </div>
          <div className="questions">
            <center>
              <span className="title">CALENDAR LINK </span>
              <span className="response">{stage.calenderLink}</span>
            </center>
          </div>
        </div>
      );
      count++;
    });
    const rules = [];
    information.rules.forEach((rule) => {
      rules.push(<div>★ {rule}</div>);
    });
    const toggle1 = () => {
      setVisible1(!visible1);
    };
    const toggle2 = () => {
      setVisible2(!visible2);
    };
    const toggle3 = () => {
      setVisible3(!visible3);
    };
    const toggle4 = () => {
      setVisible4(!visible4);
    };
    const toggle5 = () => {
      setVisible5(!visible5);
    };
    const toggle6 = () => {
      setVisible6(!visible6);
    };
    // const prizelist = [];
    // for(const prize in information.prizes) {
    //     prizelist.push(
    //         <div className="prizebox">
    //             <h3 className="win"><EmojiEventsIcon/></h3>
    //             <h3>{prize}</h3>
    //             <h3>{information.prizes[prize]}</h3>
    //         </div>
    //     );
    // }
    return (
      <div className="event-details">
        <div className="event-details-content">
          <div className="event-banner"></div>
          <div className="info">
            <div className="banner">
              {width >= 800 && (
                <img src={information.posterWeb} alt="Event Banner"></img>
              )}
              {width < 800 && (
                <img src={information.posterMobile} alt="Event Banner"></img>
              )}
            </div>
            <div className="event-name">
              <h1>
                <strong>{information.name}</strong>
              </h1>
              <h4>
                Organized by <strong>{information.organizer}</strong>
              </h4>
            </div>
            <div className="event-register">
              {/* {(status == 1 || status == 3) && (
                <button className="register-button" onClick={register}>
                  REGISTER
                </button>
              )} */}
              {/* {status == 2 && (
                <button
                  className="register-button"
                  style={{ backgroundColor: "gray" }}
                >
                  REGISTRATION COMPLETED
                </button>
              )} */}
              <button
                className="register-button"
                style={{ backgroundColor: "gray" }}
              >
                REGISTRATION COMPLETED
              </button>
            </div>
          </div>
        </div>
        <div className="event-additional heading" onClick={toggle1}>
          <h2>
            <div className="left">GENERAL INFORMATION</div>
          </h2>
        </div>
        {visible1 && (
          <>
            <div className="event-additional">
              <h2>
                <strong>{information.organizer}</strong>
              </h2>
              <h4>{information.descriptionOrganizer}</h4>
            </div>
            <div className="event-additional">
              <div className="schedule">
                <h3 className="question">FEES</h3>
                <h3 className="answer">INR {information.fees}</h3>
              </div>
              <div className="mode">
                <h3 className="question">MODE OF CONDUCTING</h3>
                <h3 className="answer">{information.mode}</h3>
              </div>
            </div>
          </>
        )}
        <div className="event-additional heading" onClick={toggle2}>
          <h2>
            <div className="left">PROBLEM STATEMENT AND RULES</div>
          </h2>
        </div>
        {visible2 && (
          <>
            <div className="event-additional">
              <div className="questions">
                <span className="title">Problem Statements: </span>
                <br></br>
                <span className="response">
                  ★ {information.problemStatements}
                </span>
              </div>
              <br></br>
              <div className="questions">
                <span className="title">Eligibility: </span>
                <span className="response">
                  Team from any recognized engineering college/Institute is
                  eligible to participate. All Participants must bring their
                  respective ID cards.
                </span>
              </div>
              <div className="questions">
                <span className="title">Team Specification: </span>
                <span className="response">
                  Any participating team can have a minimum of{" "}
                  {information.minTeamSize} members and a maximum of{" "}
                  {information.maxTeamSize} members.{" "}
                </span>
              </div>
              <div className="questions">
                <span className="title">Team Name: </span>
                <span className="response">
                  Every team must have an appropriate name. The team's name
                  can’t be changed once submitted.
                </span>
              </div>
              <div className="questions">
                <span className="title">Team Representative: </span>
                <span className="response">
                  Each team must specify their Team Representative (Leader) at
                  the time of registration on the Website.
                </span>
              </div>
              <br></br>
              <div className="notice">
                All important communications between {information.name} event
                organizers and the registered teams will be done through their
                team representatives. <br></br>The team representatives must
                submit valid contact details (mobile no., email id etc.) at the
                time of the registration.
              </div>
              <br></br>
              <div className="questions">
                <span className="title">Rules: </span>
                <span className="response">{rules}</span>
              </div>
              <br></br>
              <div className="questions">
                <center>
                  <Box>
                    <a
                      href={information.pdfLink}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "lightgreen" }}
                    >
                      <PictureAsPdfIcon fontSize="large" /> View Detailed
                      Problem Statement
                    </a>
                  </Box>
                </center>
              </div>
            </div>
          </>
        )}
        <div className="event-additional heading" onClick={toggle3}>
          <h2>
            <div className="left">ADDITIONAL INFORMATION</div>
          </h2>
        </div>
        {visible3 && (
          <>
            <div className="event-additional">
              <div className="questions">
                <div className="title">Task: </div>
                <div className="response">{task}</div>
              </div>
              <br></br>
              {extracontent}
            </div>
          </>
        )}
        <div className="event-additional heading" onClick={toggle4}>
          <h2>
            <div className="left">STAGES</div>
          </h2>
        </div>
        {visible4 && (
          <div className="event-additional">
            <h2>
              <strong>Stages</strong>
            </h2>
            {stages}
          </div>
        )}
        <div className="event-additional heading" onClick={toggle5}>
          <h2>
            <div className="left">COORDINATORS</div>
          </h2>
        </div>
        {visible5 && (
          <div className="coordinators">
            <h2>
              <strong>Event Coordinators</strong>
            </h2>
            {coordinators}
          </div>
        )}
        <div className="event-additional heading" onClick={toggle6}>
          <h2>
            <div className="left">PRIZE</div>
          </h2>
        </div>
        {visible6 && (
          <div className="prizes">
            <center>
              <h2>
                <strong>Prizes & Rewards</strong>
              </h2>
              <h3 className="prizesworth">Prizes Worth {information.prizes}</h3>
              {/* {prizelist} */}
            </center>
          </div>
        )}
      </div>
    );
  }
}

export default EventDescription;
