import React, { useEffect, useState } from "react";
import "./styles.css";
import { Height } from "@mui/icons-material";

const YourComponent = ({ user }) => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const abcd = (e) => {
    setIsButtonClicked(false);
  };
  const defg = (e) => {
    setIsButtonClicked(true);
  };
  return (
    <>
      {isButtonClicked ? (
        <div className="col-lg-3 col-md-4 col-sm-9">
          <article className="material-card Green mc-active">
            <h2 style={{ height: "135px" }}>
              <span>{user?.name}</span>
              <strong>{user?.position}</strong>
              {user?.team && <strong>{user?.team}</strong>}
            </h2>
            <div className="mc-content">
              <div className="img-container">
                <img className="responsive-img" src={user?.image} />
              </div>
            </div>
            {/* <div class="mc-description"> */}
            <div className="mc-description mc-footer">
              <h4>Social</h4>
              <a href={user?.insta_url} className="fa fa-fw fa-instagram"></a>
              <a href={user?.linkedin_url} className="fa fa-fw fa-linkedin"></a>
              {/* </div> */}
            </div>
            <a className="mc-btn-action" onClick={abcd}>
              <i className="fa fa-arrow-left"></i>
            </a>
          </article>
        </div>
      ) : (
        <div className="col-lg-3 col-md-4 col-sm-9">
          <article className="material-card Green">
            <h2>
              <span>{user?.name}</span>
              <strong>
                <span> {user?.position} </span>
                <span className="teamname"> {user?.team}</span>
              </strong>
              <strong></strong>
            </h2>
            <div className="mc-content">
              <div className="img-container">
                <img className="responsive-img" src={user?.image} />
              </div>
            </div>
            <div class="mc-description">
              <div className="mc-description mc-footer">
                <h4>Social</h4>
                <a href={user?.insta_url} className="fa fa-fw fa-instagram"></a>
                <a
                  href={user?.linkedin_url}
                  className="fa fa-fw fa-linkedin"
                ></a>
              </div>
            </div>
            <a className="mc-btn-action" onClick={defg}>
              <i className="fa fa-bars"></i>
            </a>
          </article>
        </div>
      )}
    </>
  );
};

export default YourComponent;
