import styled from "@emotion/styled";
import Box from "@mui/material/Box";

export const Wrapper = styled(Box)({
  ".time-content": {
    display: "flex",
    width: "auto",
    columnGap: "30px",
    alignItems: "center",
    border: "1px solid white",
    padding: "2rem 2.5rem",
    borderRadius: "0.5rem",
    background: "rgba(250, 250, 250, 0.2)",
  },

  ".time": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  ".number": {
    fontWeight: "800",
    fontSize: "80px",
    lineHeight: "1",
    color: "#eee",
  },

  ".text": {
    textTransform: "capitalize",
    color: "#fff",
    fontWeight: "600",
    fontSize: "12px",
  },

  "@media (max-width:520px)": {
    ".time-content": {
      display: "flex",
      width: "auto",
      columnGap: "15px",
      alignItems: "center",
      margin: "2rem",
      border: "1px solid white",
      padding: "2rem 2.5rem",
      borderRadius: "0.5rem",
      background: "rgba(250, 250, 250, 0.2)",
    },

    ".number": {
      fontWeight: "600",
      fontSize: "40px",
      lineHeight: "1",
      color: "#eee",
    },

    ".text": {
      textTransform: "capitalize",
      color: "#fff",
      fontWeight: "600",
      fontSize: "7px",
    },
  },
});
