import React from "react";
import Countdown from "react-countdown";
import { Box } from "@mui/material";
import { Wrapper } from "./Styles";
function Count() {
  const date1 = new Date();
  date1.setFullYear(2023, 9, 13);
  date1.setHours(0);
  date1.setMinutes(0);
  date1.setSeconds(0);
  const date2 = new Date();

  let difference = date1.getTime() - date2.getTime();

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    var day1 = days % 10;
    var day0 = (days - day1) / 10;
    var hour0 = hours % 10;
    var hour1 = (hours - hour0) / 10;
    var min1 = minutes % 10;
    var min0 = (minutes - min1) / 10;
    var sec1 = seconds % 10;
    var sec0 = (seconds - sec1) / 10;
    if (completed) {
      return <></>;
    } else {
      return (
      <Wrapper>
        <Box id="flipdown" className="flipdown flipdown__theme-dark">
          <Box className="rotor-group">
            <Box className="rotor-group-heading" data-before="Days"></Box>
            <Box>
              <Box className="rotor">
                <Box className="rotor-leaf ">
                  <figure className="rotor-leaf-rear ">{day0}</figure>
                  <figure className="rotor-leaf-front ">{day0}</figure>
                </Box>
                <Box className="rotor-top">{day0}</Box>
                <Box className="rotor-bottom">{day0}</Box>
              </Box>

              <Box className="rotor">
                <Box className="rotor-leaf">
                  <figure className="rotor-leaf-rear days1">{day1}</figure>
                  <figure className="rotor-leaf-front days1">{day1}</figure>
                </Box>
                <Box className="rotor-top days1">{day1}</Box>
                <Box className="rotor-bottom days1">{day1}</Box>
              </Box>
            </Box>
          </Box>
          <Box className="rotor-group">
            <Box className="rotor-group-heading" data-before="Hours"></Box>
            <Box>
              <Box className="rotor">
                <Box className="rotor-leaf">
                  <figure className="rotor-leaf-rear">{hour1}</figure>
                  <figure className="rotor-leaf-front">{hour1}</figure>
                </Box>
                <Box className="rotor-top">{hour1}</Box>
                <Box className="rotor-bottom">{hour1}</Box>
              </Box>
              <Box className="rotor">
                <Box className="rotor-leaf">
                  <figure className="rotor-leaf-rear">{hour0}</figure>
                  <figure className="rotor-leaf-front">{hour0}</figure>
                </Box>
                <Box className="rotor-top">{hour0}</Box>
                <Box className="rotor-bottom">{hour0}</Box>
              </Box>
            </Box>
          </Box>
          <Box className="rotor-group">
            <Box className="rotor-group-heading" data-before="Minutes"></Box>
            <Box>
              <Box className="rotor">
                <Box className="rotor-leaf">
                  <figure className="rotor-leaf-rear">{min0}</figure>
                  <figure className="rotor-leaf-front">{min0}</figure>
                </Box>
                <Box className="rotor-top">{min0}</Box>
                <Box className="rotor-bottom">{min0}</Box>
              </Box>
              <Box className="rotor">
                <Box className="rotor-leaf">
                  <figure className="rotor-leaf-rear">{min1}</figure>
                  <figure className="rotor-leaf-front">{min1}</figure>
                </Box>
                <Box className="rotor-top">{min1}</Box>
                <Box className="rotor-bottom">{min1}</Box>
              </Box>
            </Box>
          </Box>
          <Box className="rotor-group">
            <Box className="rotor-group-heading" data-before="Seconds"></Box>
            <Box>
              <Box className="rotor">
                <Box className="rotor-leaf">
                  <figure className="rotor-leaf-rear">{sec0}</figure>
                  <figure className="rotor-leaf-front">{sec0}</figure>
                </Box>
                <Box className="rotor-top">{sec0}</Box>
                <Box className="rotor-bottom">{sec0}</Box>
              </Box>
              <Box className="rotor">
                <Box className="rotor-leaf flipped">
                  <figure className="rotor-leaf-rear">{sec1}</figure>
                  <figure className="rotor-leaf-front">{sec1}</figure>
                </Box>
                <Box className="rotor-top">{sec1}</Box>
                <Box className="rotor-bottom">{sec1}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Wrapper>
      );
    }
  };

  return <Countdown date={Date.now() + difference} renderer={renderer} />;
}

export default Count;
