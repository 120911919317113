import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import bg1 from "../assets/bg1.jpg";

export const Wrapper = styled(Box)({
  ".background": {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100%",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.043), rgba(0, 0, 0, 0.221)), url(${bg1})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundBlendMode: "multiply",
    transform:
      "translateZ(-1px) scale(1.5)" /* Adjust the scale for the parallax effect */,
    zIndex: "-1",
  },
  ".layout": {
    // position:" relative",
    // height: "100vh", /* Adjust this value as needed */
    overflow: "hidden",
  },
  ".body": {
    // marginTop:"25%",
    // position:" relative",
    minHeight: "100vh",
    zIndex: "1",
  },
});
