import { useNavigate } from "react-router-dom";

const EventCard = ({
  imgSrc,
  title,
  prizes,
  organizer,
  venue,
  category,
  eventId,
  isInformal,
}) => {
  const navigate = useNavigate();

  const limitCharacters = (text, limit) => {
    if (text.length > limit) {
      return text.slice(0, limit) + "...";
    }
    return text;
  };

  return (
    <li className="cards_item">
      <div className="card10">
        <div className="card-main glass-morphism">
          <div className="card-img-shadow">
            <img src={imgSrc} alt={title} />
          </div>
          <div className="card-content">
            <h2 style={{ color: "#f7f75d" }}>{limitCharacters(title, 30)}</h2>
            <p
              style={{
                fontFamily: "ReFormationSansRegular",
                lineHeight: "auto",
              }}
            >
              Organizer: {limitCharacters(organizer, 35)}
            </p>
            <p
              style={{
                fontFamily: "ReFormationSansRegular",
                lineHeight: "auto",
                margin: "0.3rem 0",
              }}
            >
              Prize: {limitCharacters(prizes, 15)}
            </p>
            <p
              style={{
                fontFamily: "ReFormationSansRegular",
                lineHeight: "auto",
                margin: "0.3rem 0",
              }}
            >
              Venue: {venue}
            </p>
            {!isInformal && (
              <div className="button-shadow">
                <button
                  className="card-button"
                  onClick={() => navigate(`/eventdescription/${eventId}`)}
                >
                  View More
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default EventCard;
