import { useState } from "react";
import toast from "react-hot-toast";
import {
  Box,
  Typography,
  Chip,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import Form from "react-bootstrap/Form";
import { customFetchMultipart } from "../../utils/axios";
import Uu from "./size";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import one from "../../assets/imagestshirt/1.webp";
import two from "../../assets/imagestshirt/2.webp";
import three from "../../assets/imagestshirt/3.webp";
import four from "../../assets/imagestshirt/4.webp";
import five from "../../assets/imagestshirt/5.png";
import six from "../../assets/imagestshirt/6.webp";
import seven from "../../assets/imagestshirt/7.webp";
import { Wrapper } from "./Styles";
const images = [
  { url: one },
  { url: two },
  { url: three },
  { url: four },
  { url: seven },
  { url: six },
  { url: five },
];

// const FormContainer = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(2),
// }));

const InputDefault = styled(TextField)({
  "& label.Mui-focused": {
    color: "#2F3C7E",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#8AAAE5", // Change this color
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#317773", // Change this color
    },
    "&:hover fieldset": {
      borderColor: "#E2D1F9", // Change this color
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ADD8E6", // Change this color
    },
  },
  "& input": {
    color: "#FFFFFF", // Change the text color
  },
  "& .MuiFormLabel-root": {
    color: "#FFFFFF", // Change the label text color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#FFFFFF", // Change the focused label text color
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#FFFFFF", // Change the placeholder color
  },
});

const flexContainerStyle = {
  display: "flex",

  justifyContent: "center",
  flexWrap: "wrap", // Allow content to wrap to th next line
};

const responsiveColumn = {
  display: "flex",
  flexBasis: "100%", // Make each item take full width in a column
  padding: "5px", // Adjust spacing as needed
  justifyContent: "space-between",
};
export default function App() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [admNo, setAdmNo] = useState("");
  const [email, setEmail] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [branch, setBranch] = useState("");
  const [hostel, setHostel] = useState("");
  const [transId, setTransId] = useState("");
  const [room, setRoom] = useState("");
  const [size, setSize] = useState("");
  const [url, setUrl] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      if (!url) {
        return toast.error("Attach screenshot of the payment !");
      }
      if (email.endsWith("iitism.ac.in")) {
        return toast.error("Enter personal Email Id");
      }

      setLoading(true);
      const formData = new FormData();

      const response = {
        orderID: transId,
        name: name,
        admissionNumber: admNo,
        mobileNumber: mobNo,
        branch: branch,
        tshirtSize: size,
        transactionID: transId,
        hostel: hostel,
        roomNumber: room,
        image: url,
        email,
      };

      for (let prop in response) {
        formData.append(prop, response[prop]);
      }

      // console.log(formData);
      const res = await customFetchMultipart.post(`/api/purchase`, formData);
      // console.log("res = ", res);

      // resetting values
      setName("");
      setAdmNo("");
      setEmail("");
      setMobNo("");
      setBranch("");
      setHostel("");
      setTransId("");
      setRoom("");
      setSize("");
      setUrl("");
      setLoading(false);
      toast.success(res.data.msg);
    } catch (error) {
      setLoading(false);
      console.log("e = ", error);
      toast.error(
        error.response.data.msg || "Something went wrong! Try contacting admins"
      );
      // console.log("error", error);
    }
  };
  const handleImageChange = (event) => {
      const file = event.target.files[0]; // Get the selected file
      const maxSizeInBytes = 500 * 1024;
      if (file && file.size > maxSizeInBytes) {
        // The selected file exceeds the size limit
        toast.error("Image size exceeds 500KB. Please choose a smaller image.");
        event.target.value = null; // Clear the file input field
      }
      else{
      setUrl(event.target.files[0]);
      console.log(url);
    }
  };

  return (
    <Wrapper>
      <div id="canvas_container2">
        {loading && (
          <Box
            sx={{
              position: "fixed",
              zIndex: "9999999",
              top: "50%",
              height: "100%",
              width: "100vw",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
        <div
          className="m-3"
          id="canvas_box2"
          style={{ opacity: loading ? "0.5" : "1" }}
        >
          <div style={flexContainerStyle}>
            <Box className="tshirt-carousel" sx={{ zIndex: "999" }}>
              <Carousel
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
              >
                {images.map((el, idx) => (
                  <Box key={idx} className="tshirt-carousel-img">
                    <img
                      src={el.url}
                      alt="tshirt"
                      style={{ objectFit: "contain", objectPosition: "center" }}
                    />
                  </Box>
                ))}
              </Carousel>
            </Box>
            <div
              className="text-light glass-morphism"
              style={{ borderRadius: "10px" }}
            >
              <Typography variant="h5" className="mb-3 padd" align="center">
                Enter Your Information
              </Typography>
              <Form className="m-3" onSubmit={submitHandler}>
                <div style={responsiveColumn}>
                  <InputDefault
                    label="Name"
                    placeholder="XYZ PQR"
                    type="text"
                    fullWidth
                    required
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div style={responsiveColumn}>
                  <InputDefault
                    label="Admission Number"
                    placeholder="21JEXXXX"
                    type="text"
                    fullWidth
                    required
                    value={admNo}
                    onChange={(e) => {
                      setAdmNo(e.target.value);
                    }}
                  />
                </div>
                <div style={responsiveColumn}>
                  <InputDefault
                    label="Email"
                    type="email"
                    placeholder="abc@gmail.com"
                    fullWidth
                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div style={responsiveColumn}>
                  <InputDefault
                    label="Phone Number"
                    placeholder="8340XXXXXX"
                    type="tel"
                    fullWidth
                    required
                    value={mobNo}
                    onChange={(e) => {
                      setMobNo(e.target.value);
                    }}
                  />
                </div>
                <div style={responsiveColumn}>
                  <InputDefault
                    label="Branch"
                    placeholder="Civil Engineering"
                    type="text"
                    required
                    value={branch}
                    sx={{ width: "48%", color: "white" }}
                    onChange={(e) => {
                      setBranch(e.target.value);
                    }}
                  />
                  <InputDefault
                    label="Transaction id"
                    placeholder="T23091XXXXXXXXX"
                    required
                    value={transId}
                    sx={{ width: "48%", color: "white" }}
                    onChange={(e) => {
                      setTransId(e.target.value);
                    }}
                  />
                </div>
                <div style={responsiveColumn} className="hostel">
                  <InputDefault
                    label="Hostel"
                    sx={{ width: "48%", color: "white" }}
                    type="text"
                    placeholder="Amber"
                    required
                    value={hostel}
                    onChange={(e) => {
                      setHostel(e.target.value);
                    }}
                  />

                  <InputDefault
                    label="Room No"
                    placeholder="A-230"
                    sx={{ width: "48%", color: "white" }}
                    required
                    value={room}
                    onChange={(e) => {
                      setRoom(e.target.value);
                    }}
                  />
                </div>
                <div style={responsiveColumn}>
                  <label htmlFor="upload-photo" className="custom-file-upload">
                    <Chip
                      sx={{
                        backgroundColor: "white",
                        color: "black",
                        borderRadius: "4px",
                      }}
                      label="Payment screenshot"
                    />
                    &nbsp;
                    {url?.name}
                  </label>
                  <input
                    className="tshirt-input"
                    style={{ display: "none" }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                </div>
                <Uu size={size} setSize={setSize} />
                <Box>
                  <Button
                    type="submit"
                    className="m-3"
                    variant="contained"
                    sx={{ transform: "none", left: "0" }}
                  >
                    Place order
                  </Button>
                </Box>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
