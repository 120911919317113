import React from "react";
import { Wrapper, BeautifiedSocialMediaLink } from "./Styles.jsx";
import { Box, Typography } from "@mui/material";
import { Link as RRLink } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Email } from "@mui/icons-material";

const socialNetworks = [
  {
    socialHandle: "facebook",
    link: "https://www.facebook.com/Concettoiitdhanbad/",
    icon: <FacebookIcon sx={{ fontSize: "2em" }} />,
    iconColor: "#0866ff",
  },
  {
    socialHandle: "linkeldn",
    link: "https://www.linkedin.com/company/concetto-iitism-dhanbad/",
    icon: <LinkedInIcon sx={{ fontSize: "2em" }} />,
    iconColor: "#0e76a8",
  },
  {
    socialHandle: "instagram",
    link: "https://www.instagram.com/concetto.iitism/?hl=en",
    icon: <InstagramIcon sx={{ fontSize: "2em" }} />,
    iconColor: "#cc2467",
  },
];

const Footer = () => {
  return (
    <Wrapper>
      <Box className="hello1">
        {/* <Box className="clip-border" /> */}
        <Box className="hello">
          <Box className="row1">
            <Box className="contact col">
              <Typography variant="h6" className="row-title first-title">
                Quick Contact
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                If you have any questions or need help, feel free to contact
                with our team.
              </Typography>
              <Box
                component={RRLink}
                sx={{ textDecoration: "none" }}
                to="mailto:concetto@iitism.ac.in"
              >
                <Email /> concetto@iitism.ac.in
              </Box>
              <Typography variant="body2">
                Indian Institute of Technology (ISM)
                <br />
                Dhanbad - 826004, Jharkhand, India
              </Typography>
            </Box>
            <Box className="about col">
              <Typography variant="h6" className="row-title">
                Important Links
              </Typography>
              <Typography variant="body2" to="events" component={RRLink}>
                Events
              </Typography>
              <Typography
                variant="body2"
                to="events"
                component="a"
                className="mobile-nav-links"
                href="https://linktr.ee/Concetto_Workshops"
                target="_blank"
                rel="noreferrer"
              >
                Workshops
              </Typography>
              <Typography variant="body2" to="aboutUs" component={RRLink}>
                About Us
              </Typography>
              <Typography variant="body2" to="teams" component={RRLink}>
                Teams
              </Typography>
              <Typography
                variant="body2"
                component={RRLink}
                to="https://www.iitism.ac.in/"
              >
                IIT (ISM)
              </Typography>
            </Box>
            {/* <Box className="merchandise col">
              <Typography variant="h6" className="row-title">
                Merchandise
              </Typography>
              <Typography variant="body2" to="tshirt" component={RRLink}>
                T-shirt
              </Typography>
            </Box> */}
            <Box className="sponsors col">
              <Typography variant="h6" className="row-title">
                Sponsorship
              </Typography>

              <Typography
                variant="body2"
                component={RRLink}
                to="mailto:sponsorship.concetto@iitism.ac.in"
                sx={{ textDecoration: "none" }}
              >
                <Email sx={{ fontSize: "1.25em" }} />{" "}
                sponsorship.concetto@iitism.ac.in
              </Typography>
            </Box>
          </Box>
          <Box className="social-media-icons">
            {socialNetworks.map(({ link, icon, iconColor }, idx) => (
              <BeautifiedSocialMediaLink
                key={idx}
                href={link}
                target="_blank"
                rel="noreferrer"
                iconColor={iconColor}
              >
                {icon}
              </BeautifiedSocialMediaLink>
            ))}
            {/* <Typography>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. A
              corporis, nemo hic enim aperiam explicabo quo nisi odit magnam?
              Nostrum nobis ducimus doloremque dolore at quisquam. Et facilis
              esse fugit adipisci asperiores. Nobis, id.
            </Typography> */}
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default Footer;
