const scrollToSponsor = () => {
  // console.log("clicked ...");
  const block = document.querySelector("#sponsors");
  const scrollTriggerPoint = block.offsetTop ;
  window.scrollTo({
    top: scrollTriggerPoint,
    behavior: "smooth",
  });
};

export default scrollToSponsor;
