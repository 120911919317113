import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Routes, Route, useLocation } from "react-router-dom";
import {
  Error,
  Events,
  EventNotExist,
  EventRegister,
  Tshirt,
  Home,
  Team,
  AboutUs,
  Dashboard,
  Accomodation
} from "./pages";
import { AdminLogin, EventDescription, Sponsors } from "./components";
import { init, animate } from "./components/Background/heroSection";
import SharedLayout from "./sharedLayout/layout";
import AdminLayout from "./sharedLayout/AdminLayout";
import "./App.css";

init();
animate();

function App() {
  const location = useLocation();

  useEffect(() => {
    // console.log("here ...");
    // Wait for the component to render
    const block = document.querySelector("#canvas_container");
    const parallaxWrapper = document.querySelector(".sponsor");

    // console.log(location);
    if (block) {
      // remove block for pages other then home page
      if (location.pathname !== "/") {
        block.style.display = "none";
      } else {
        block.style.display = "block";
      }
    }

    if (block && parallaxWrapper) {
      // scroll react component only when block is completely scrolled
      const scrollTriggerPoint = block.offsetTop + block.offsetHeight;
      const handleScroll = () => {
        if (window.scrollY >= scrollTriggerPoint) {
          parallaxWrapper.style.overflow = "auto";
        } else {
          parallaxWrapper.style.overflow = "hidden";
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [location.pathname]);

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            zIndex: "9999999",
          },
        }}
      />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/sponsors" element={<Sponsors />} />
        <Route element={<SharedLayout />}>
          <Route exact path="/events" element={<Events />} />
          <Route exact path="/eventnotexit" element={<EventNotExist />} />
          <Route exact path="/aboutUs" element={<AboutUs />} />
          <Route exact path="/teams" element={<Team />} />
          <Route
            exact
            path="/eventdescription/:formId"
            element={<EventDescription />}
          />
          <Route
            exact
            path="/eventregister/:eventId"
            element={<EventRegister />}
          />
          <Route exact path="/tshirt" element={<Tshirt />} />
          <Route exact path="/acc" element={<Accomodation />} />
        </Route>
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<AdminLogin />} />
          <Route path="dashboard" element={<Dashboard />} />
        </Route>

        <Route exact path="*" element={<Error />} />
      </Routes>
    </>
  );
}

export default App;
